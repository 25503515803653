/*  ==========================================================================
    IMPRESSIONS V3
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__impressions-v4--arrows: $clr__ci--tertiary;
$module-clr__impressions-v4--arrows-hover: $clr__ci--secondary;
$module-clr__impressions-v4--placeholder: $clr__ci--primary;

/*
 * MODULE
 */

.bn-impressions-v4 {
  .bn-impressions-v4__arrows {
    @include slider--arrows-v2($module-clr__impressions-v4--arrows,$module-clr__impressions-v4--arrows-hover);
    margin-bottom: 0 !important;
  }
  .bn-impressions-v4__head{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    &.bn-titleset{
      justify-content: space-between;
    }
  }
  .bn-impressions-v4__title{
    text-transform: uppercase;
    font-size: 3em;
    font-weight: $typo__fw--regular;
  }
  .bn-impressions-v4__slider {
    overflow: hidden;
    .bn-impressions-v4__item {
      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .bn-impressions-v4__single,
      .bn-impressions-v4__plural {
        .bn-impressions-v4__image {
          background-color: $clr__black;
          display: block;
          > div {
            overflow: hidden;
          }
        }
      }
      .bn-impressions-v4__single {
        width: calc(66.66% - 5px);
        .bn-impressions-v4__image {
          img {
            @include img--responsive();
          }
        }
      }
      .bn-impressions-v4__plural {
        overflow: hidden;
        position: relative;
        width: calc(33.33% - 5px);
        &:before,
        &:after {
          @include aspect-ratio(4,3);
          background-color: rgba($module-clr__impressions-v4--placeholder,0.1);
          content: '';
          display: block;
          position: absolute;
          right: 0;
          width: 100%;
        }
        &:before {
          top: -5px;
        }
        &:after {
          bottom: -5px;
        }
        .bn-impressions-v4__image  {
          bottom: 0;
          height: calc(50% - 5px);
          overflow: hidden;
          position: absolute;
          right: 0;
          width: 100%;
          z-index: 5;
          > div {
            height: 100%;
            position: relative;
            width: 100%;
          }
          img {
            @include img--cover-v2();
          }
          &:first-child {
            bottom: auto;
            margin-bottom: 10px;
            top: 0;
          }
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-impressions-v4 {
    .bn-impressions-v4__title{
      font-size: 2em;
    }
    .bn-impressions-v4__slider {
      .bn-impressions-v4__item {
        .bn-impressions-v4__single {
          width: calc(66.66% - 2px);
        }
        .bn-impressions-v4__plural {
          width: calc(33.33% - 2px);
          &:before {
            top: -2px;
          }
          &:after {
            bottom: -2px;
          }
          .bn-impressions-v4__image {
            height: calc(50% - 2px);
          }
        }
      }
    }
  }
}

@media  (max-width: 350px){
  .bn-impressions-v4 {
    .bn-impressions-v4__title{
      font-size: 1.8em;
    }
  }
}