/*  ==========================================================================
    TEXT PIC BOX V4
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__text-pic-box-v4--bg-primary: $clr__ci--primary;
$module-clr__text-pic-box-v4--bg-secondary: $clr__ci--secondary;
$module-clr__text-pic-box-v4--bg-tertiary: $clr__ci--tertiary;
$module-clr__text-pic-box-v4--typo-primary: $clr__typo--primary;
$module-clr__text-pic-box-v4--typo-secondary: $clr__typo--secondary;
$module-clr__text-pic-box-v4--typo-tertiary: $clr__typo--primary;
$module-clr__text-pic-box-v4--accent-primary: $clr__ci--secondary;
$module-clr__text-pic-box-v4--accent-secondary: $clr__ci--primary;
$module-clr__text-pic-box-v4--accent-tertiary: $clr__ci--primary;

/*
 * MODULE
 */

.bn-text-pic-box-v4 {
  .bn-text-pic-box-v4__frame {
    justify-content: space-between;
    .bn-text-pic-box-v4__content,
    .bn-text-pic-box-v4__images {
      display: flex;
      max-width: 50%;
      > div {
        width: 100%;
      }
    }
    .bn-text-pic-box-v4__content {
      align-items: flex-start;
      order: 2;
      > div {
        padding: 40px;
        position: relative;
        &:after {
          content: '';
          display: block;
          height: 100%;
          left: 0;
          margin-left: -50%;
          position: absolute;
          top: 0;
          width: 50%;
        }
      }
      .bn-text-pic-box-v4__headline {
        margin-bottom: 40px;
      }
      .bn-text-pic-box-v4__text {
        @include typo--table-lock();
      }
      .bn-text-pic-box-v4__button {
        margin-top: 20px;
      }
    }
    .bn-text-pic-box-v4__images {
      align-items: flex-start;
      overflow: hidden;
      order: 1;
      padding-top: 40px;
      position: relative;
      z-index: 5;
      > div {
        @include clearfix();
        margin-top: -20px;
      }
      .bn-text-pic-box-v4__image {
        display: block;
        padding-top: 20px;
        img {
          @include img--responsive();
        }
        > div {
          overflow: hidden;
        }
      }
      // output according to number of pictures
      &.bn-text-pic-box-v4__images--number-1 {
        margin-top: -40px;
        padding-top: 0;
        .bn-text-pic-box-v4__image {
          width: 100%;
        }
      }
      &.bn-text-pic-box-v4__images--number-2,
      &.bn-text-pic-box-v4__images--number-3 {
        .bn-text-pic-box-v4__image {
          float: right;
          width: calc(40% - 10px);
          &:first-child {
            float: left;
            width: calc(60% - 10px);
          }
        }
      }
    }
  }
  // colors
  &.bn-text-pic-box-v4--clr-primary {
    .bn-text-pic-box-v4__frame {
      .bn-text-pic-box-v4__content {
        > div {
          background-color: $module-clr__text-pic-box-v4--bg-primary;
          &:after {
            background-color: $module-clr__text-pic-box-v4--bg-primary;
          }
        }
        .bn-text-pic-box-v4__headline {
          @include typo--subheadline($module-clr__text-pic-box-v4--typo-primary);
        }
        .bn-text-pic-box-v4__text {
          @include typo--rte($module-clr__text-pic-box-v4--typo-primary,$module-clr__text-pic-box-v4--accent-primary);
        }
        .bn-text-pic-box-v4__button {
          .bn-button {
            @include button--custom(transparent,$module-clr__text-pic-box-v4--typo-primary,$module-clr__text-pic-box-v4--typo-primary,transparent,$module-clr__text-pic-box-v4--accent-primary,rgba($module-clr__text-pic-box-v4--typo-primary, 0.5));
            border: none;
            padding: 0;
            min-width: 0;
          }
        }
      }
    }
  }
  &.bn-text-pic-box-v4--clr-secondary {
    .bn-text-pic-box-v4__frame {
      .bn-text-pic-box-v4__content {
        > div {
          background-color: $module-clr__text-pic-box-v4--bg-secondary;
          &:after {
            background-color: $module-clr__text-pic-box-v4--bg-secondary;
          }
        }
        .bn-text-pic-box-v4__headline {
          @include typo--subheadline($module-clr__text-pic-box-v4--typo-secondary);
        }
        .bn-text-pic-box-v4__text {
          @include typo--rte($module-clr__text-pic-box-v4--typo-secondary,$module-clr__text-pic-box-v4--accent-secondary);
        }
        .bn-text-pic-box-v4__button {
          .bn-button {
            @include button--custom(transparent,$module-clr__text-pic-box-v4--typo-secondary,$module-clr__text-pic-box-v4--typo-secondary,transparent,$module-clr__text-pic-box-v4--accent-secondary,$module-clr__text-pic-box-v4--accent-secondary);
          }
        }
      }
    }
  }
  &.bn-text-pic-box-v4--clr-tertiary {
    .bn-text-pic-box-v4__frame {
      .bn-text-pic-box-v4__content {
        > div {
          background-color: $module-clr__text-pic-box-v4--bg-tertiary;
          &:after {
            background-color: $module-clr__text-pic-box-v4--bg-tertiary;
          }
        }
        .bn-text-pic-box-v4__headline {
          @include typo--subheadline($module-clr__text-pic-box-v4--typo-tertiary);
        }
        .bn-text-pic-box-v4__text {
          @include typo--rte($module-clr__text-pic-box-v4--typo-tertiary,$module-clr__text-pic-box-v4--accent-tertiary);
        }
        .bn-text-pic-box-v4__button {
          .bn-button {
            @include button--custom(transparent,$module-clr__text-pic-box-v4--typo-tertiary,$module-clr__text-pic-box-v4--typo-tertiary,transparent,$module-clr__text-pic-box-v4--accent-tertiary,$module-clr__text-pic-box-v4--accent-tertiary);
          }
        }
      }
    }
  }
  // mirror
  &.bn-text-pic-box-v4--mirror {
    .bn-text-pic-box-v4__frame {
      .bn-text-pic-box-v4__content {
        order: 1;
        > div {
          &:after {
            left: auto;
            margin-left: 0;
            margin-right: -50%;
            right: 0;
          }
        }
      }
      .bn-text-pic-box-v4__images {
        order: 2;
        // output according to number of pictures
        &.bn-text-pic-box-v4__images--number-2,
        &.bn-text-pic-box-v4__images--number-3 {
          .bn-text-pic-box-v4__image {
            float: left;
            &:first-child {
              float: right;
            }
          }
        }
      }
    }
  }
  // if only one image exists
  &.bn-text-pic-box-v4--single-image {
    .bn-text-pic-box-v4__frame {
      padding-top: 40px !important;
    }
  }
  // spacing to next text pic element
  + .bn-text-pic-box-v4 {
    margin-top: 20px;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-text-pic-box-v4 {
    .bn-text-pic-box-v4__frame {
      .bn-text-pic-box-v4__content {
        > div {
          padding-left: 20px;
          padding-right: 20px;
        }
        .bn-text-pic-box-v4__headline {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-text-pic-box-v4 {
    .bn-text-pic-box-v4__frame {
      display: block;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .bn-text-pic-box-v4__content,
      .bn-text-pic-box-v4__images {
        display: block;
        max-width: 100%;
        padding-top: 0;
      }
      .bn-text-pic-box-v4__content {
        padding: 40px;
        > div {
          background-color: transparent !important;
          padding: 0;
          &:after {
            display: none;
          }
        }
      }
      .bn-text-pic-box-v4__images {
        > div {
          margin-top: 0;
        }
        .bn-text-pic-box-v4__image {
          display: none;
          padding-top: 10px;
          width: calc(50% - 5px) !important;
          &:nth-child(-n+2){
            display: block;
          }
          &:first-child:nth-last-child(1) {
            width: 100% !important;
          }
        }
        // output according to number of pictures
        &.bn-text-pic-box-v4__images--number-1 {
          margin-top: 0;
        }
      }
    }
    // colors
    &.bn-text-pic-box-v4--clr-primary {
      .bn-text-pic-box-v4__frame {
        .bn-text-pic-box-v4__content {
          background-color: $module-clr__text-pic-box-v4--bg-primary;
        }
      }
    }
    &.bn-text-pic-box-v4--clr-secondary {
      .bn-text-pic-box-v4__frame {
        .bn-text-pic-box-v4__content {
          background-color: $module-clr__text-pic-box-v4--bg-secondary;
        }
      }
    }
    &.bn-text-pic-box-v4--clr-tertiary {
      .bn-text-pic-box-v4__frame {
        .bn-text-pic-box-v4__content {
          background-color: $module-clr__text-pic-box-v4--bg-tertiary;
        }
      }
    }
    // if only one image exists
    &.bn-text-pic-box-v4--single-image {
      .bn-text-pic-box-v4__frame {
        padding-top: 0 !important;
      }
    }
    // spacing to next text pic element
    + .bn-text-pic-box-v4 {
      margin-top: 10px;
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-text-pic-box-v4 {
    .bn-text-pic-box-v4__frame {
      .bn-text-pic-box-v4__content {
        padding: 20px;
      }
    }
  }
}