/*  ==========================================================================
    WEATHER DETAIL
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__weather-detail--bg-primary: $clr__ci--primary;
$module-clr__weather-detail--bg-secondary: $clr__ci--secondary;
$module-clr__weather-detail--bg-tertiary: $clr__ci--tertiary;
$module-clr__weather-detail--copyright: $clr__typo--primary;
$module-clr__weather-detail--table: $clr__typo--primary;
$module-clr__weather-detail--title: $clr__typo--primary;
$module-clr__weather-detail--typo-primary: $clr__typo--secondary;
$module-clr__weather-detail--typo-secondary: $clr__typo--secondary;
$module-clr__weather-detail--typo-tertiary: $clr__typo--primary;

/*
 * MODULE
 */

.bn-weather-detail {
  .bn-weather-detail__holder {
    .bn-weather-forecast {
      margin-top: 80px;
      .bn-weather-title {
        @include typo--subheadline($clr__typo--primary);
        margin-bottom: 20px;
      }
      .bn-weather-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .bn-weather-box__content,
        .bn-weather-box__media {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          padding: 40px;
          width: calc(50% - 10px);
        }
        &.bn-weather-box--today,
        &.bn-weather-box--tomorrow {
          .bn-weather-box__media {
            img {
              @include img--responsive();
            }
          }
        }
        &.bn-weather-box--today {
          .bn-weather-box__content {
            @include typo--rte($module-clr__weather-detail--typo-primary,$module-clr__weather-detail--typo-primary);
            background-color: $module-clr__weather-detail--bg-primary;
            order: 2;
          }
          .bn-weather-box__media {
            order: 1;
          }
        }
        &.bn-weather-box--tomorrow {
          .bn-weather-box__content {
            @include typo--rte($module-clr__weather-detail--typo-secondary,$module-clr__weather-detail--typo-secondary);
            background-color: $module-clr__weather-detail--bg-secondary;
          }
        }
        &.bn-weather-box--evolution {
          .bn-weather-box__content {
            @include typo--rte($module-clr__weather-detail--typo-tertiary,$module-clr__weather-detail--typo-tertiary);
            background-color: $module-clr__weather-detail--bg-tertiary;
            order: 2;
          }
          .bn-weather-box__media {
            order: 1;
            .bn-weather-box__table {
              text-align: center;
              width: 100%;
              .bn-weather-box__dates,
              .bn-weather-box__reliabilities,
              .bn-weather-box__temps,
              .bn-weather-box__icons {
                td {
                  padding: 0 10px;
                }
              }
              .bn-weather-box__dates,
              .bn-weather-box__reliabilities,
              .bn-weather-box__temps {
                font-size: 1.6em;
              }
              .bn-weather-box__icons {
                font-size: 6.0em;
              }
            }
          }
        }
      }
      &:first-child {
        margin-top: 0;
      }
    }
    .bn-weather-copyright {
      @include typo--rte($module-clr__weather-detail--copyright,$module-clr__weather-detail--copyright);
      margin-top: 80px;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-weather-detail {
    .bn-weather-detail__holder {
      .bn-weather-forecast {
        .bn-weather-box {
          .bn-weather-box__content,
          .bn-weather-box__media {
            padding: 20px;
          }
          &.bn-weather-box--evolution {
            .bn-weather-box__media {
              .bn-weather-box__table {
                .bn-weather-box__dates,
                .bn-weather-box__reliabilities,
                .bn-weather-box__temps,
                .bn-weather-box__icons {
                  td {
                    padding: 0 5px;
                  }
                }
                .bn-weather-box__dates,
                .bn-weather-box__reliabilities,
                .bn-weather-box__temps {
                  font-size: 1.2em;
                }
                .bn-weather-box__icons {
                  font-size: 4.0em;
                }
              }
            }
          }
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-weather-detail {
    .bn-weather-detail__holder {
      .bn-weather-forecast {
        margin-top: 40px;
        .bn-weather-box {
          display: block;
          .bn-weather-box__content,
          .bn-weather-box__media {
            display: block;
            width: 100%;
          }
          .bn-weather-box__media {
            margin-top: 20px;
          }
        }
      }
      .bn-weather-copyright {
        margin-top: 40px;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-weather-detail {
    > .bn-grid__row {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .bn-weather-detail__holder {
      .bn-weather-forecast {
        margin-top: 20px;
        .bn-weather-title {
          padding: 0 20px;
        }
        .bn-weather-box {
          .bn-weather-box__media {
            margin-top: 10px;
          }
          &.bn-weather-box--evolution {
            .bn-weather-box__media {
              .bn-weather-box__table {
                .bn-weather-box__dates,
                .bn-weather-box__reliabilities,
                .bn-weather-box__temps {
                  font-size: 1.0em;
                }
                .bn-weather-box__icons {
                  font-size: 3.0em;
                }
              }
            }
          }
        }
      }
      .bn-weather-copyright {
        margin-top: 20px;
        padding: 0 20px;
      }
    }
  }
}