/*  ==========================================================================
    RICH SNIPPET REVIEW
    ========================================================================== */
/*
 * COLORS
 */

$module-clr__rs-review--accent: #fff;
$module-clr__rs-review--typo: $clr__typo--primary;
$module-clr__rs-review--star: $clr__ci--primary;

/*
 * MODULE
 */

.bn-rs-review {
  .bn-rs-review__holder {
    text-align: center;
    .bn-rs-review__company {
      @include typo--subheadline($module-clr__rs-review--typo);
    }
    .bn-rs-review__stars {
      color: rgba($module-clr__rs-review--typo, 0.5);
      font-size: 2.5em;
      margin: 5px 0;
      .bn-rs-review__star {
        display: none;
        padding: 0 2px;
        &.bn-icon--star-full,
        &.bn-icon--star-half {
          color: #fff;
        }
      }
      &.bn-rs-review__stars--max-1 {
        .bn-rs-review__star-1 {
          display: inline-block;
        }
      }
      &.bn-rs-review__stars--max-2 {
        .bn-rs-review__star-1,
        .bn-rs-review__star-2 {
          display: inline-block;
        }
      }
      &.bn-rs-review__stars--max-3 {
        .bn-rs-review__star-1,
        .bn-rs-review__star-2,
        .bn-rs-review__star-3 {
          display: inline-block;
        }
      }
      &.bn-rs-review__stars--max-4 {
        .bn-rs-review__star-1,
        .bn-rs-review__star-2,
        .bn-rs-review__star-3,
        .bn-rs-review__star-4 {
          display: inline-block;
        }
      }
      &.bn-rs-review__stars--max-5 {
        .bn-rs-review__star-1,
        .bn-rs-review__star-2,
        .bn-rs-review__star-3,
        .bn-rs-review__star-4,
        .bn-rs-review__star-5 {
          display: inline-block;
        }
      }
      &.bn-rs-review__stars--max-6 {
        .bn-rs-review__star-1,
        .bn-rs-review__star-2,
        .bn-rs-review__star-3,
        .bn-rs-review__star-4,
        .bn-rs-review__star-5,
        .bn-rs-review__star-6 {
          display: inline-block;
        }
      }
    }
    .bn-rs-review__info {
      color: $module-clr__rs-review--typo;
      font-size: 1.4em;
      a {
        color: $module-clr__rs-review--typo;
        &:visited {
          color: $module-clr__rs-review--typo;
        }
        &:hover {
          color: $module-clr__rs-review--accent;
          text-decoration: underline;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
