/*  ==========================================================================
    REQUEST
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__request--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-request {
  .bn-request__holder {
    //background-color: rgba($module-clr__request--bg,0.1); Optional
    .bn_request_variation__vertical {
      @include request--v1();
    }
  }
}

//Disable Offerpreview on Offerpoup & Offerdetail
.popupForm,
.detailForm{
  .ractive--offer-lastminute-Template{
    display: none;
  }
}

//Set White as Standard Background
.bn-form-request .bn-form__grid .bn-form__block{
  background-color: #fff;
}
.bn_request-23205_validation__popup .bn-form-error {
  color: white !important;
}
.bn_request-23205_validation__popup li {
  border-bottom-color: #fff!important;
}
.bn_request__popup .bn-form-error {
  color: #fff !important;
}
body .bn_request__popup li {
  border-bottom-color: #fff!important;
}
body .eh-request .bn_request .bn-form__button-container .bn-form__button:hover {
  background-color: #fff!important;
}

//Flatpicker Index
body .bn_request_flatpickr{
  z-index: 200 !important;
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */