/*  ==========================================================================
    ROUTE PLANNER
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__route-planner--border: $clr__gray;
$module-clr__route-planner--map-bg: $clr__black;
$module-clr__route-planner--text: $clr__typo--primary;

/*
 * MODULE
 */

.bn-route-planner {
  > .bn-grid__row {
    > .bn-grid__col {
      > div {
        border: 1px solid $module-clr__route-planner--border;
        padding: 10px;
      }
    }
  }

  .bn-route-planner__map {
    background-color: $module-clr__route-planner--map-bg;
    height: 500px;
    position: relative;

    .bn-loader {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .bn-route-planner__controls {
    position: absolute;
    top: 0;
    right: 100px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;

    .bn-route-planner__form {
      display: flex;
      align-items: center;
      .bn-route-planner__start {
        width: 200px;
        height: 40px;
        color: $clr__ci--primary;
        font-size: 1.5em;
      }

      .bn-route-planner__submit {
        border: 0;
        width: 150px;
        height: 40px;
        margin-left: 10px;
        background-color: #fff;
        font-weight: 400;
        font-size: 1.5em;
        cursor: pointer;
        color: $clr__ci--primary;
        &.bn-button--small {
        }

        &:hover {

        }
      }
    }
    .bn-route-planner__directions {
      @include scrollbar($clr__scrollbar, 5px);
      font-size: 16px;
      overflow-y: auto;
      padding-left: 10px;
      width: 70%;
    }
  }
}
.bn-google-maps {
  margin-top: 5em;
  position: relative;
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-route-planner {
    .bn-route-planner__map {
      height: 400px;
    }
    .bn-route-planner__controls {
      .bn-route-planner__form {
        width: 40%;
      }
      .bn-route-planner__directions {
        width: 60%;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-google-maps {
    margin-top: 8em;
  }
  .bn-route-planner {
    .bn-route-planner__map {
      height: 300px;
    }
    .bn-route-planner__controls {
      display: block;
      top: -60px;
      right: auto;
      left: 15px;

      .bn-route-planner__directions {
        font-size: 14px;
        padding-left: 0;
        padding-top: 10px;
      }
    }
  }
}

@media #{$mq__phone} {
  .bn-google-maps {
    margin-top: 13em;
  }
  .bn-route-planner {
    .bn-route-planner__controls {
      top: -100px;
      width: 100%;
      left: 0;
      padding: 0 20px;
      .bn-route-planner__form {
        flex-direction: column;
        width: 100%;
        .bn-route-planner__start {
          width: 100%;
        }
        .bn-route-planner__submit {
          width: 100%;
          margin: 0;
          margin-top: 7px;
        }
      }
    }
  }
}