/*  ==========================================================================
    COLORS
    List of all colors concerning your project.

    INFO:
    - change your project related colors here
    - if needed change also the specific colors
    - don't delete colors, only add (if possible here: custom)!
    - for naming help of additional custom colors use
      "http://chir.ag/projects/name-that-color/#000000"
    ========================================================================== */

/*
 * GENERAL
 */

$clr__black: #000000;
$clr__bn-red: #AE173B;
$clr__gray: #808080;
$clr__white: #FFFFFF;

/*
 * PROJECT RELATED
 */

// corporate identity
$clr__ci--primary: #626262;
$clr__ci--secondary: #DAD2C2;
$clr__ci--tertiary: #fff;

// typography
$clr__typo--primary: #fff;
$clr__typo--secondary: #626262;

// call to action
$clr__cta--primary: #626262;

// notification
$clr__correct: #008000;
$clr__error: #FF0000;

/*
 * SPECIFIC
 */

$clr__page: #5D5956;
$clr__progress-bar: $clr__bn-red;
$clr__scrollbar: $clr__bn-red;
$clr__selection: #ae173b;

/*
 * CUSTOM
 */

$clr__download-icon: #8D1314;