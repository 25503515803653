/*  ==========================================================================
    HEADER V4
    ========================================================================== */

@mixin header--v4() {
  @include header--v3();
  padding-left: $unit__wdt--header-v3-sidebar-desktop-large;
  // sidebar
  .bn-sidebar {
    display: flex;
    // main logo
    .bn-main-logo {
      &.bn-main-logo--aside {
        width: 100%;
        .bn-main-logo__image {
          width: 100%;
          &.bn-main-logo__image--big {
            display: inline-block;
          }
          &.bn-main-logo__image--small {
            display: none;
          }
        }
      }
    }
    //add arrow to menu with submenu
    .bn-main-menu__item--has-children .bn-main-menu__title::after{
      content: "\e902";
      font-family: 'bn-icons' !important;
      font-size: .8em;
      margin-left: 5px;
    }

    // close
    .bn-close {
      display: none;
    }
    // lang menu
    .bn-lang-menu {
      &.bn-lang-menu--aside {
        display: none;
      }
    }
    // call to action
    .bn-call-to-action {
      &.bn-call-to-action--aside {
        display: block;
      }
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      display: inline-block;
      height: $unit__hgt--header-v4_lang-menu;
      line-height: $unit__hgt--header-v4_lang-menu;
      pointer-events: initial;
      ul {
        @include clearfix();
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
          }
          &.bn-lang-menu__item--act {
            a {
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // full screen on
  @media #{$mq__full-screen--on} {
    position: absolute;
  }
}